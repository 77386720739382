import React from 'react'
import Fade from 'react-reveal/Fade'
import styled from 'styled-components';

const Title = styled.div`
font-size: 1.1em;
font-weight: 600;
margin-bottom: 0.4em;
`

const Description = styled.div`
font-size: 1.1em;
line-height: 1.6;
color: #051220;
font-weight: 400;
margin-bottom: 0.4em;
`

const ContainerLeft = styled.div`
width: 15%;

@media only screen and (min-width: 375px) and (max-width: 812px) {
  width: 100%;
  margin-bottom: 0.8em;
}
`
const ContainerRight = styled.div`
width: 50%;
margin-left: 10%;

@media only screen and (min-width: 375px) and (max-width: 812px) {
  width: 100%;
  margin-left: 0em;
}
`

const MainContainer = styled.div`
width: 80%;
margin: auto;
padding: 4em 0em 1em 0em;
display: flex;
flex-direction: row;
justify-content: flex-start;

@media only screen and (min-width: 375px) and (max-width: 812px) {
  padding-top: 0em;
  width: 80%;
  margin: 2em auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

`

const TextBlock2col = props => (

	    <MainContainer>
	    	<ContainerLeft>
		    		<Fade bottom duration={450} distance="1.4em" ssrFadeout>
		    			<Title>{props.title1}</Title>
		    			<Description>{props.description1}</Description>
		    		</Fade>
			</ContainerLeft>
			<ContainerRight>
				<Fade bottom duration={450} distance="1.4em" ssrFadeout>
		    			<Description>{props.description}</Description>
		    	</Fade>
			</ContainerRight>
		</MainContainer>

)

export default TextBlock2col