import React, { Component } from 'react'
import Link from 'gatsby-link'
// import ChevronLeft from 'rmdi/lib/ChevronLeft'
// import ChevronRight from 'rmdi/lib/ChevronRight'
import styled from 'styled-components';


 const FooterContainer = styled.div`
  width: 80%;
  margin: 8em auto 4em auto; 
  font-weight: 700;
`
 const LeftContainer = styled.div`
  width: 50%;
  display: inline-block;
`
 const RightContainer = styled.div`
  width: 50%;
  text-align: right;
  display: inline-block;
`
 const PreviousLink = styled.div`
  width: 50%;
  text-align: left;
  display: flex;
  align-items: center;
`
 const NextLink = styled.div`
  width: 50%;
  text-align: right;
  display: inline-block;
  align-items: center;
`



const FooterCaseStudy = props => (
  <div>
    <FooterContainer>
      <LeftContainer>
        <PreviousLink>
           {/*<Link to=""><ChevronLeft size={27} mb={-2} mr={1} color='#0065ff'/>Previous</Link>*/}
        </PreviousLink>
      </LeftContainer>
      <RightContainer>
        <NextLink>
          {/* <Link to=""> Next<ChevronRight size={27} mb={-2} ml={1} color='#0065ff'/></Link>*/}
        </NextLink>
      </RightContainer>
    </FooterContainer>
  </div>
)

export default FooterCaseStudy